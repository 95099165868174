
<template>
  <div class="application-package-wrapper"
       :style="{'background':theme.worktop_background}">
    <module-box @emitHandleEvent="handleFn"
                :appModule="appModule"
                :title="title">
      <ul class="cnt-style"
          v-if="list.length">
        <li v-for="(item,index) in list"
            :key="`${index}_${item.modelAlias}`"
            :class="{'checked': item.checked}"
            @click="checkedItem(item, index)">
          <div :class="['icon-bg',`color-${(index % 6) + 1}`]">
            <img :src="item.icon"
                alt=""
                class="icon">
          </div>
          <span class="title" :style="{'color':theme.worktop_primary_text_color}">{{item.modelAlias}}</span>
          <div class="itemList"
               :style="{'background':theme.worktop_model_background}">
            <div class="item"
                 v-for="(menu,i) in item.childreds"
                 @click.stop="junpPage(menu)"
                 :key="`${i}_${menu.url}`">
              <span class="item-text"
                    :style="{'color':theme.worktop_primary_text_color}">{{menu.modelAlias}}</span>
            </div>
          </div>
        </li>
      </ul>
    </module-box>
  </div>
</template>

<script>
import ModuleBox from './ModuleBox'
import { menuJump } from 'common/menuHandler'

export default {
  computed: {
    theme () {
      return this.$store.state.theme
    }
  },
  components: {
    ModuleBox
  },
  props: {
    appModule: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  watch: {
    appModule (v) {
      this.title = v.modelAlias
      this.formatData(v.childreds)
    }
  },
  data () {
    return {
      title: this.appModule.modelAlias || '',
      list: []
    }
  },
  mounted () {
    this.formatData(this.appModule.childreds)
    document.addEventListener('click', () => {
      this.checkedItem()
    })
  },
  methods: {
    formatData (list = []) {
      this.list = []
      list.forEach(item => {
        item.checked = false
        this.list.push({
          checked: false,
          modelAlias: item.modelAlias,
          childreds: item.childreds,
          icon: item.packageIcon
        })
      })
    },
    handleFn (data) {
      console.log(data)
    },
    checkedItem (item, index) {
      let list = this.list || []
      list.forEach((item, i) => {
        item.checked = false
      })
      setTimeout(() => {
        item && (item.checked = !item.checked)
      }, 0)
    },
    junpPage (menu) {
      const { menuUrl, authType, urlJumpType } = menu
      if ([1, 2].includes(urlJumpType)) {
        menuJump({
          url: menuUrl,
          authType,
          urlJumpType
        })
      } else {
        this.$router.push({
          name: menuUrl
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.application-package-wrapper {
  border-radius: 5px;
  .search-box {
    line-height: normal;
  }
}
.cnt-style {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 18px 14px;
  box-sizing: border-box;
  li {
    min-width: 154px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: left;
    cursor: pointer;
    .icon-bg {
      width: 44px;
      height: 48px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      margin-left: 10px;
      .icon {
        width: 20px;
        height: 20px;
        margin-top: 10px;
      }
    }
    .title {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 16px;
    }
    .itemList {
      position: absolute;
      top: 65px;
      left: 0;
      z-index: 100;
      display: none;
      width: 100%;
      box-shadow: 0px 0px 10px 0px rgba(27, 140, 242, 0.3);
      border-radius: 4px;
      cursor: default;
      .item {
        padding: 10px 15px;
        .item-text {
          display: block;
          border-radius: 16px;
          width: 100%;
          height: 32px;
          cursor: pointer;
          line-height: 32px;
          text-align: center;
          background: rgba(114, 188, 255, 0.2);
          &:hover {
            background: rgba(114, 188, 255, 1);
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
    &.checked {
      box-shadow: 0px 0px 10px 0px rgba(27, 140, 242, 0.3);
      .itemList {
        display: block;
      }
    }
    .color-1 {
      background-image: url("./images/icon_blue@2x.png");
    }
    .color-2 {
      background-image: url("./images/icon_cyan@2x.png");
    }
    .color-3 {
      background-image: url("./images/icon_green@2x.png");
    }
    .color-4 {
      background-image: url("./images/icon_pink@2x.png");
    }
    .color-5 {
      background-image: url("./images/icon_purple@2x.png");
    }
    .color-6 {
      background-image: url("./images/icon_yellow@2x.png");
    }
  }
}
</style>
