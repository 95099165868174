var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "application-package-wrapper",
      style: { background: _vm.theme.worktop_background },
    },
    [
      _c(
        "module-box",
        {
          attrs: { appModule: _vm.appModule, title: _vm.title },
          on: { emitHandleEvent: _vm.handleFn },
        },
        [
          _vm.list.length
            ? _c(
                "ul",
                { staticClass: "cnt-style" },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: `${index}_${item.modelAlias}`,
                      class: { checked: item.checked },
                      on: {
                        click: function ($event) {
                          return _vm.checkedItem(item, index)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { class: ["icon-bg", `color-${(index % 6) + 1}`] },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: { src: item.icon, alt: "" },
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "title",
                          style: {
                            color: _vm.theme.worktop_primary_text_color,
                          },
                        },
                        [_vm._v(_vm._s(item.modelAlias))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "itemList",
                          style: {
                            background: _vm.theme.worktop_model_background,
                          },
                        },
                        _vm._l(item.childreds, function (menu, i) {
                          return _c(
                            "div",
                            {
                              key: `${i}_${menu.url}`,
                              staticClass: "item",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.junpPage(menu)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "item-text",
                                  style: {
                                    color: _vm.theme.worktop_primary_text_color,
                                  },
                                },
                                [_vm._v(_vm._s(menu.modelAlias))]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }